import React from 'react'
import { Route, Routes } from "react-router-dom";

import OrdenesCompras from '../../Components/Admin/OrdenesCompras/OrdenesCompras'
import OrdenCompraDetalle from '../../Components/Admin/OrdenesCompras/OrdenCompraDetalle'

/**
 * 
 * @export
 * @function RouterCategorias
 * @description Router for Ordenes de compra routes 
 */
function RouterOrdenesCompras(props) {
  return (
    <Routes>
      <Route path="" element={<OrdenesCompras {...props} />} />
      <Route path="/:orden_compra_id" element={<OrdenCompraDetalle {...props} />} />
    </Routes>
  )
}

export default RouterOrdenesCompras