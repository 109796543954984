import React from 'react';
import { Row, Col, Typography, Image } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const categorias = [
	{ nombre: 'HIDRÁULICA', image: './productos/1.jpg', link: "hidraulica" },
	{ nombre: 'TUBING Y TUBOS', image: './productos/2.jpg', link: "tubing_y_tubos" },
	{ nombre: 'ÓLEO HIDRÁULICA', image: './productos/3.jpg', link: "oleo_hidraulica" },
	{ nombre: 'MANGUERAS Y CONEXIONES', image: './productos/4.jpg', link: "mangueras_y_conexiones" },
	{ nombre: 'NEUMÁTICA', image: './productos/5.jpg', link: "neumatica" },
	{ nombre: 'INSTRUMENTACIÓN', image: './productos/6.jpg', link: "instrumentación" },
	{ nombre: 'HULES SELLOS Y EMPAQUES', image: './productos/7.jpg', link: "hules_sellos_y_empaques" },
	{ nombre: 'AUTO TRANSPORTE SERVICIO PESADO', image:'./productos/8.png', link: "transporte_pesado" },
	{ nombre: 'EQUIPOS PARA GASOLINERAS', image: './productos/9.jpg', link: "equipo_para_gasolineras" },
	{ nombre: 'EQUIPOS Y HERRAMIENTAS', image: './productos/10.jpg', link: "equipos_y_herramientas" },
	{ nombre: 'SERVICIOS TÉCNICOS', image: './productos/11.jpg', link: "servicios_tecnicos" },
];

const Nosotros = () => {

	const navigate = useNavigate()
	return (
		<Row gutter={[16, 16]}>
			<Col span={24}>
				<Title className='subtitle text-center' style={{
					textTransform: "uppercase",
					color: "#EE492A",
					fontSize: "16px",
					fontWeight: "400",
					fontFamily: "Ubuntu",
					letterSpacing: "1px"}}>NUESTRAS CATEGORIAS</Title>
			</Col>
			{categorias.map((categoria, index) => (
				<Col key={index} xs={24} md={6}>
					<div className={`div-card`} onClick={()=>navigate("/categorias/"+categoria.link)}>
						<div className='center'>
							<Image className='div-image' src={categoria.image} height={200} preview={false}/>
						</div>
						<Title level={3} className='card-title'>{categoria.nombre}</Title>
					</div>
				</Col>
			))}
		</Row>
	);
}

export default Nosotros;
