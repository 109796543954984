import React, { Component } from 'react'
import { Row, Col, Typography } from 'antd';

const { Paragraph, Text } = Typography;

export default class Footer extends Component {
  render() {
    return (
        <footer id="footer" >
            <Row className='wrapper' justify={"space-between"} gutter={[40, 40]}>
                <Col xs={24} sm={12} md={8} lg={9} className='about' >
                    <img src="/img/landing/logo.svg" className='about-logo' />
                    <Paragraph className='about-description'>
                        The process of adding structures to areas of land, also known as real property sites.
                    </Paragraph>
                    <nav className='about-socials'>
                        <a href="https://www.linkedin.com/in/dicofansa-s-a-de-cv-031b0b68/" target='_blank'>
                            <img src="/img/landing/social/linkedin.svg" />
                        </a>
                    </nav>
                </Col>
                <Col xs={24} sm={12} md={8} lg={8} className='offices'>
                    <Text className='offices-title'>Sucursales</Text>
                    <div className='offices-list'>
                        <Paragraph>
                            Indonesia <br />
                            Jl. Griya Permata Hijau no D1 Purwomartani Kalasan 543881
                        </Paragraph>
                    </div>
                </Col>
            </Row>
        </footer>
    )
  }
}
