import { Layout } from 'antd';
import React from 'react';
import { Route, Routes } from "react-router-dom";

// Components
import Login from '../Components/Auth/Login';
import Recovery from '../Components/Auth/Recovery';
import UpdatePassword from '../Components/Auth/UpdatePassword';
import ProductoDetalle from '../Components/Public/ProductoDetalle';
import ThemeEdit from '../Components/ThemeEdit/index';


import Landing from '../Components/Public/Landing';
import Productos from '../Components/Public/Productos';
import Register from '../Components/Auth/Register';
import Categorias from '../Components/Public/Categorias/Categorias';

/**
 * 
 * @export
 * @function PublicRoutes
 * @description Router for handling all public routes
 */
function PublicRoutes() {

  return (
    <Layout style={{position: "relative", overflow: "visible"}}>
      <Routes>
        <Route path='/' element={<Landing/>} />
        <Route path='/categorias/:categoria?' element={<Categorias/>} />
        <Route path='/productos' element={<Productos/>} />
        <Route path='/product/detail/:id' element={<ProductoDetalle />} />
        <Route path='theme' element={<ThemeEdit />} />
        <Route path='login' element={<Login />} />
        <Route path='password/recovery' element={<Recovery />} />
        <Route path='recovery/:token' element={<UpdatePassword />} />
        <Route path='register/:token' element={<UpdatePassword />} />
        <Route path='register' element={<Register />} />
      </Routes>
    </Layout>
  )
}

export default PublicRoutes;