import { Component, createRef, useContext, useState } from "react";
import { Badge, Button, Card, Col, Collapse, Drawer, Empty, Form, Image, Input, Radio, Row, Spin, Typography, message, notification } from "antd";
import gsap from "gsap";
import axios from "axios";
import { useGSAP } from "@gsap/react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import FormItem from "antd/es/form/FormItem";
import ScrollTrigger from "gsap/dist/ScrollTrigger";
import CollapsePanel from "antd/es/collapse/CollapsePanel";
import { ArrowRightOutlined, DeleteOutlined, MenuOutlined, PhoneFilled, ShopFilled, ShoppingCartOutlined } from "@ant-design/icons";

//Componentes
import Header from "./Header";
import { User } from "../../Hooks/Logged";
import DrawerCarrito from "./Carrito/Drawer";
import { CardProductoPublic } from '../Widgets/Cards';
import { Carrito, SetCarrito } from "../../Hooks/Carrito";

import '../../Styles/Modules/Public/productos.scss'

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(ScrollTrigger);

const { Title, Paragraph, Text } = Typography


class Productos extends Component {


    constructor(props) {
        super(props)

        this.state = {
            isOpen: false,
            productos: {
                data: [],
                page: 1,
                limit: 12,
                search: undefined,
                categoria_id: undefined
            },
            categorias: [],
            loadMore: false,
            openCart : false
        }
    }

    formRef = createRef()
    prodRef = createRef()

    componentDidMount() {
        this.getProductos()
        this.getCategorias()

        window.addEventListener('scroll', this.scrollHandler)
    }

    

    setVisible = () => {
        console.log("this.state.openCart", this.state.openCart)
        this.setState({ openCart: !this.state.openCart })
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.loadMore !== this.state.loadMore && this.state.loadMore) {
            this.getProductos({ page: this.state.productos.page + 1 })
            ScrollTrigger.refresh()
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.scrollHandler)
    }

    scrollHandler = (e) => {
        if (
            document.querySelector('#footer').getBoundingClientRect().top - window.innerHeight <= 0
        ) {
            const { page, limit, data } = this.state.productos
            if (page * limit === data.length) this.setState({ loadMore: true })
        }
    }

    getProductos = ({
        search = this.state.productos.search,
        page = this.state.productos.page,
        limit = this.state.productos.limit,
        categoria_id = this.state.productos.categoria_id
    } = this.state.productos) => {
        this.setState({ loading: true })
        axios.get('/public/productos', {
            params: {
                search, page, limit, categoria_id
            }
        }).then(({ data }) => {
            const productos = page === 1 ? data.data : [...this.state.productos.data, ...data.data]
            this.setState({
                productos: {
                    ...this.state.productos,
                    search,
                    page,
                    limit,
                    categoria_id,
                    data: productos
                }
            })
        }).catch(e => {
            message.error('Error al obtener los productos')
        }).finally(() => {
            this.setState({ loading: false, loadMore: false })
        })
    }

    getCategorias = () => {
        axios.get('/public/categorias')
            .then(({ data }) => {
                this.setState({ categorias: data })
            }).catch(e => {
                message.error('Error al obtener las categorias')
            })
    }

    /**
     * @memberOf Productos
     * 
     * @param {Object} values - Contiene la cantidad del producto.
     * @param {string} producto - ID del producto a añadir.
     * @descripcion Añade un elemento al carrito de compras.
     */
    addToCart = async (values, producto) => {

        // Verificar si el usuario está logueado
        if (!this.props.user) {
            notification.error({
                message: "Usuario no logueado",
                description: "Es necesario iniciar sesión para añadir productos al carrito de compra.",
                placement: "topRight",
            });
            return;
        }

        this.setState({ loading: true });

        try {

            const { data } = await axios.post('/carrito', {
                cantidad: values.cantidad,
                producto_id: producto,
            });

            // Actualizar el carrito en las cookies y en el estado global
            this.props.setCookie("cart", data._id);
            this.props.setCart(data.carrito);

            // Notificación de éxito
            message.success("Producto añadido al carrito con éxito");
        } catch (error) {
            console.error("Error al agregar el producto al carrito:", error);
            // Mostrar mensaje de error al usuario
            message.error(error?.response?.data?.message ?? "Error al agregar el producto al carrito");
        } finally {
            // Finalizar el estado de carga
            this.setState({ loading: false });
        }
    };


    


    render() {

        const { categorias, productos, loading } = this.state

        return (
            <div id="productos">
                <Header/>
                <main id="main">
                    <section className="header">
                        <Title level={1}>
                            Nuestros Productos
                        </Title>
                        <Row>
                            <Col span={24}>
                                <Input.Search 
                                    size="large" 
                                    className="search" 
                                    placeholder="Buscar Productos" 
                                    onSearch={(value)=>{
                                        this.getProductos({ search: value, page: 1 })
                                    }}
                                />
                            </Col>
                        </Row>

                    </section>
                    <section id="filters">
                        <Form className="form"
                            ref={this.formRef}
                            onValuesChange={(_, values) => {
                                this.getProductos({ categoria_id: values.categoria_id, page: 1 })
                            }}

                        >
                            {/*<Card className={'form-card'} bordered>
                                <FormItem
                                    name="search"
                                >
                                    <Input size="large" className="search" placeholder="Buscar" />
                                </FormItem>
                            </Card>*/}
                            <Card className={'form-collapse'} bordered>
                                <Collapse 
                                    bordered={false} 
                                    ghost 
                                    className="collapse"
                                    defaultActiveKey={0}
                                >
                                    <CollapsePanel header="Categorias" extra={
                                        <Button disabled={!productos.categoria_id}
                                            icon={<DeleteOutlined />}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                this.formRef?.current?.setFieldsValue({
                                                    categoria_id: undefined
                                                })
                                                this.setState(state => {
                                                    state.productos.categoria_id = undefined
                                                    return state
                                                }, () => this.getProductos({ page: 1 }))
                                            }}
                                        />
                                    }>
                                        <FormItem
                                            name="categoria_id"
                                        >
                                            <Radio.Group className="radio-group">
                                                {categorias.map(c => (
                                                    <Radio key={c._id} value={c._id}>{c.nombre}</Radio>
                                                ))}
                                            </Radio.Group>
                                        </FormItem>
                                    </CollapsePanel>
                                </Collapse>
                            </Card>
                        </Form>
                    </section>
                    <section className="list">
                        {productos?.data?.length > 0 && productos?.data.map(p => (
                            <CardProductoPublic 
                                key={p._id}
                                producto={p}
                                addToCart={this.addToCart}
                            />
                        ))}
                        {
                            loading && <div className="spin"><Spin size="large" /></div>
                        }
                        {
                            productos?.data?.length < 1 && !loading && <Empty description={"No se encontraron productos"} />
                        }
                    </section>

                </main>
                <footer id="footer" >
                    <Row className='wrapper' justify={"space-between"} gutter={[40, 40]}>
                        <Col xs={24} sm={12} md={8} lg={9} className='about' >
                            <img src="/img/landing/logo.svg" className='about-logo' />
                            <Paragraph className='about-description'>
                                The process of adding structures to areas of land, also known as real property sites.
                            </Paragraph>
                            <nav className='about-socials'>
                                <a href="https://www.linkedin.com/in/dicofansa-s-a-de-cv-031b0b68/" target='_blank'>
                                    <img src="/img/landing/social/linkedin.svg" />
                                </a>
                            </nav>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8} className='offices'>
                            <Text className='offices-title'>Sucursales</Text>
                            <div className='offices-list'>
                                <Paragraph>
                                    Indonesia <br />
                                    Jl. Griya Permata Hijau no D1 Purwomartani Kalasan 543881
                                </Paragraph>
                            </div>
                        </Col>
                    </Row>
                </footer>
                <section id={'overlay'}>
                    
                </section>
                <Drawer
                    id="mobile-menu"
                    placement="left"
                    title={null}
                    footer={null}
                    open={this.state.isOpen}
                    onClose={() => this.setState({ isOpen: false })}
                    closable={false}
                    width={280}
                >
                    <div className="links">
                        <Link className="link" to={'/'}>Inicio</Link>
                        <Link className="link" to={'/productos'}>Productos</Link>
                        <a className="link" href={"https://wa.me/8121365636"} target={'_blank'}> <PhoneFilled /> 026-123-456</a>
                    </div>
                    <Link className="button" to={'#'}>
                        Obtener una Cotización
                        <ArrowRightOutlined />
                    </Link>

                </Drawer>

                {this.state.openCart && (
                    <DrawerCarrito
                        open={this.state.openCart}
                        close={this.setVisible}
                        {...this.props}
                    />
                )}
            </div>
        )
    }
}

export default function View() {

    const [cookies, setCookie] = useCookies(['cart']);

    let cart = useContext(Carrito);
    let setCart = useContext(SetCarrito)
    let user = useContext(User)

    return <Productos 
        cookies={cookies} 
        setCookie={setCookie} 
        cart={cart} 
        setCart={setCart}
        user={user}
    />
}