import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Typography,  } from 'antd';
import { GiTargetArrows } from "react-icons/gi";
import { FaFlagCheckered } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";


const { Title, Paragraph  } = Typography;

const Nosotros = () => {


	return (
		<Row className='nosotros' gutter={[16, 16]}>
			<Col xs={24} md={8}>
				<div className='div-card orange'>
					<div className='div-icon'><GiTargetArrows/></div>
					<Title level={3} className='card-title'>Objetivo</Title>
					<Paragraph className='card-text'>
						Establecer principios y compromisos que garanticen nuestros
						servicios, proyectos, productos y materiales destinados a la
						industria en general. comprometiendonos con el crecimiento
						sostenido de la empresa y desarrollo profesional de sus
						colaboradores.
					</Paragraph>
				</div>
			</Col>
			<Col xs={24} md={8}>
				<div className='div-card white'>
					<div className='div-icon'><FaRegEye/></div>
					<Title level={3} className='card-title'>Visión</Title>
					<Paragraph className='card-text'>
						Ser una compañía lider en servicio de instalacion, mano de obra
						y comercializacion de equipos, productos hidraulicos, equipos
						para hidro carburos y la industria en general.
					</Paragraph>
				</div>
			</Col>
			<Col xs={24} md={8}>
				<div className='div-card orange'>
					<div className='div-icon'><FaFlagCheckered/></div>
					<Title level={3} className='card-title'>Misión</Title>
					<Paragraph className='card-text'>
					Proveer soluciones integrales de alta calidad en productos y
					servicios para la industria en general a nivel nacionall, con etica
					y profesionalismo, con personal comprometido y capacitado,
					garantizando la satisfaccion total del cliente.
					</Paragraph>
				</div>
			</Col>
		</Row>
	)
}

export default Nosotros