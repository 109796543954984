import { Breadcrumb, Card, Col, Layout, Row, Space, Statistic, Tabs, Typography, message, Dropdown, Menu } from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

//componentes
import dayjs from 'dayjs';
import User from '../../../Hooks/Logged';
import { RenderMoney, renderEstatusOrden } from '../../Utils';
import { CardSimpleInfo } from '../../Widgets/Cards';
import ProductosOrden from './Tabs/ProductosOrden';
import usePermissions from "../../../Hooks/usePermissions";
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import { FlagFilled, PaperClipOutlined } from '@ant-design/icons';

//css
import '../../../Styles/Modules/Admin/ventas.scss'

const { Content } = Layout;
const { Title, Text, Link, Paragraph } = Typography
const moment = require('moment')
moment.locale('es');


/**
 *
 *
 * @export
 * @class OrdenCompraDEtalle
 * @extends {Component}
 * @description Vista donde se muestra la informacion de un orden
 */
class OrdenCompraDEtalle extends Component {

    back = () => {
        this.props.navigate(-1);
    }

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            orden_compra_id: this.props.params.orden_compra_id,
            orden: {

            },
        }
    }


    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if (this.props.params.orden_compra_id) {
            this.getOrden()
        }
    }


    /**
     *
     * @memberof DetalleInversion
     * 
     * @method getOrden
     * @description Obtiene informacion del Venta
     */
    getOrden = () => {
        this.setState({ loading: true })
        axios.get('/orden-compra/' + this.state.orden_compra_id, )
            .then(response => {
                this.setState({
                    orden: response.data
                })

            }).catch(error => {
                console.log("error", error);
                message.error("Error al obtener la informacion del catálogo ")
            }).finally(() => this.setState({ loading: false }))
    }

    updateEstatus = (orden_id, status) => {
        axios.put('/orden-compra', {
            orden_compra_id: orden_id,
            status
        }).then(success => {
            message.success("Estatus de venta actualizada")
            this.getOrden()
        }).catch(error => {
            message.error("Error al actualizar el estatus")
        })
    }


    render() {

        const { orden } = this.state

        let direccion = Array.isArray(orden?.detalles) && orden.detalles.length > 0 ? orden.detalles[0]?.direccion : {};

        const items = []
        if(this.props.verDetalles) items.push({
            key: 'productos',
            label: 'Detalles',
            children: <ProductosOrden getData={() => this.getOrden()} orden_compra_id={this.props.params.orden_compra_id} />,
        })

        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title={<Breadcrumb
                        className="breadcrumb-header"
                        items={[
                            {
                                title: "Ordenes de Compra",
                                onClick: () => this.props.navigate('/admin/ordenes-compras'),
                                className: "link"
                            },
                            {
                                title: ` ${orden?.folio}`,
                            },
                        ]}
                    />}
                >
                </PageHeader>
                <Content className="pd-1 venta">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={12} xl={8} className="grid">
                            <Card className='venta-info'>
                                <Row gutter={[8, 8]}>
                                    <Col span={24}>
                                        <Text className='info-order'>Folio: {orden.folio} /  ID: {orden.order_id}</Text>
                                    </Col>
                                    <Col span={24} className="info-date">
                                        <Space>
                                            <FlagFilled/>
                                            <Text>Emitido el {dayjs(orden.fecha).format('DD/MM/YYYY')}</Text>
                                        </Space>
                                    </Col>
                                    <Col span={24}>
                                        <Text>Contacto: {orden.contacto?.nombre}</Text>
                                    </Col>
                                    <Col span={24}>
                                        <Text>Email: {orden.contacto?.email}</Text>
                                    </Col>
                                    <Col span={24}>
                                        <Text>{orden.contacto?.telefono}</Text>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} md={12} xl={8} className="grid">
                            <Card className=''>
                                <Row gutter={[8, 8]}>
                                    <Col span={24}>
                                        <Text className='info-title'>Total Venta</Text>
                                    </Col>
                                    <Col span={24}>
                                        <RenderMoney monto={orden.total} suffix={orden.moneda} valueStyle={{ fontSize: "32px", fontWeight: "bold" }}/>
                                    </Col>
                                    <Col span={24}>
                                    	<Dropdown
                                            overlay={<Menu>
                                                <Menu.Item onClick={() => this.updateEstatus(orden._id, 1)}>
                                                    <Space direction="horizontal" size={8}>
                                                        <div className={`ventas-circle small circle-1`} />
                                                        <Text>Pendiente</Text>
                                                    </Space>
                                                </Menu.Item>
                                                <Menu.Item onClick={() => this.updateEstatus(orden._id, 2)}>
                                                    <Space direction="horizontal" size={8}>
                                                        <div className={`ventas-circle small circle-2`} />
                                                        <Text>Completo</Text>
                                                    </Space>
                                                </Menu.Item>
                                                <Menu.Item onClick={() => this.updateEstatus(orden._id, 3)}>
                                                    <Space direction="horizontal" size={8}>
                                                        <div className={`ventas-circle small circle-3`} />
                                                        <Text>Cancelado</Text>
                                                    </Space>
                                                </Menu.Item>
                                            </Menu>}
                                            className="ventas-dropdown"
                                            trigger={['click']}
                                        >
                                            <div>{renderEstatusOrden(orden.status)}</div>
                                        </Dropdown>
                                        
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col xs={24} md={12} xl={8} className="grid">
                            <Card className='orden-info'>
                                <Row gutter={[8, 8]}>
                                    <Col span={12}>
                                        <div className='info-tag'><Text>Dirección</Text></div>
                                    </Col>
                                    <Col span={24}>
                                        <Space direction='vertical' size={2}>
                                            <Text>{direccion?.nombre}</Text>
                                            <Text>{direccion?.calle}</Text>
                                            <Text>CP. {direccion?.codigo_postal ?? '-'}, {direccion?.ciudad ?? '--'}</Text>
                                            <Text>{direccion?.estado ?? '-'}, {direccion?.pais ?? 'MX'}</Text>
                                            <Text>Tel. {direccion?.telefono ?? '-'}</Text>
                                            <Text>Datos: {direccion?.informacion?.join(", ") ?? '-'}</Text>
                                        </Space>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>

                    <Tabs
                        defaultActiveKey="productos"
                        destroyInactiveTabPane={true}
                        items={items}
                    />

                </Content>
            </>
        )
    }
}


export default function (props) {

    const params = useParams()
    const navigate = useNavigate()

    
    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        verDetalles: ["ventas", "productos-ventas", "access"]
    })


    return <OrdenCompraDEtalle  {...props} params={params} navigate={navigate} rol={user?.rol_id?.permisos}
        {...permisos}
    />
}