import React, { Component } from "react";
import { Row, Col, Modal, Form, message, Spin, InputNumber } from 'antd';
import axios from "axios"

import { getResponseError } from "../../Utils";
import SelectProducto from "../../Widgets/Inputs/SelectProducto";
import SelectVariante from "../../Widgets/Inputs/SelectVariante";
import SelectCategoria from "../../Widgets/Inputs/SelectCategoria";



/**
 * @class ModalInventarios
 * @description Modal para el CRUD de Productos
 */
class ModalInventarios extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,

        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if (this.props.inventario_id)
            this.getInventario()

    }

    formRef = React.createRef();

    /**
    * @method getInventario
    * @description Obtener al producto
    */
    getInventario = () => {
        this.setState({ loading: true })
        axios.get('/inventario/' + this.props.inventario_id)
            .then(({ data }) => {
                console.log("data", data);
                this.formRef.current.setFieldsValue({
                    ...data,
                    producto_id: data.producto_id ? {
                        value: data.producto_id?._id,
                        label: data.producto_id?.nombre,
                    } : null,
                    variante_id: data.variante_id ? {
                        value: data.variante_id?._id,
                        label: data.variante_id?.nombre,
                    } : null,
                })
                this.setState({ producto_id: data.producto_id?._id })
            }).catch(error => {
                console.log("error", error);
                message.error('Error getting the product')
            }).finally(() => this.setState({ loading: false }))
    }


    /**
    * @method add
    * @description Añade un nuevo registro de producto
    */
    add = (values) => {
        this.setState({ loading: true })
        axios.post('/inventarios', values)
            .then(({data}) => {
                message.success('Inventory Created!')
                this.props.onClose(true, data)
            }).catch(error => {
                console.log("error", error);
                message.error(getResponseError(error.response, 'Error creating inventory'))
            }).finally(() => this.setState({ loading: false }))
    }

    /**
    * @method updateUser
    * @description Añade un nuevo registro de producto
    */
    update = (values) => {

        this.setState({ loading: true })
        axios.put('/inventarios', values, {
            headers: {
                "Content-Type": 'multipart/form-data'
            }
        })
            .then(({data}) => {
                message.success('Updated Inventory!')
                this.props.onClose(true, data)
            }).catch(error => {
                console.log("error", error);
                message.error(getResponseError(error.response, 'Error updating the Inventory'))
            }).finally(() => this.setState({ loading: false }))
    }

    /**
   * @method onFinish
   * @description Se ejecuta al hacer submit al formulario
   */
    onFinish = (values) => {

        if (this.props.inventario_id) {
            values.inventario_id = this.props.inventario_id
            this.update(values)
        } else {
            this.add(values)
        }
    }

    render() {
        return (
            <Spin spinning={this.state.loading}>
                <Form
                    id="form-producto"
                    layout={"vertical"}
                    onFinish={this.onFinish}
                    ref={this.formRef}
                    className="mt-3"
                    initialValues={{
                        activo: true
                    }}
                >
                    <Row justify="center" className="center">
                        <Col span={20}>
                            <Form.Item
                                name="producto_id"
                                label="Producto"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Producto es requerido',
                                    }
                                ]}
                            >
                                <SelectProducto
                                    disabled={this.props.inventario_id}
                                    onSelect={(producto_id) => {
                                        this.setState({ producto_id })
                                        this.formRef.current.setFieldsValue({ variante_id: undefined })
                                    }}
                                    onClear={() => {
                                        this.setState({ producto_id: undefined })
                                        this.formRef.current.setFieldsValue({ variante_id: undefined })
                                    }}
                                />
                            </Form.Item>
{/* 
                            <Form.Item
                                name="categoria_id"
                                label="Categoría"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Categoría es requerido',
                                    }
                                ]}
                            >
                                <SelectCategoria
                                    params={{
                                        producto_id: this.state.producto_id,
                                    }}
                                />
                            </Form.Item> */}

                            <Form.Item
                                name="existencias"
                                label="Existencias"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Existencias es requerido',
                                    }
                                ]}
                            >
                                <InputNumber
                                    className="width-100"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                    min={0.01}
                                />
                            </Form.Item>


                        </Col>
                    </Row>
                </Form>
            </Spin>
        )
    }


}



export default function Modals(props) {

    const { open = false, onClose = () => { }, inventario_id } = props

    return <Modal
        open={open}
        onCancel={onClose}
        title={inventario_id ? "Editar Inventario" : "Crear Inventario"}
        closable={true}
        destroyOnClose={true}
        okText="Guardar"
        cancelText="Cancelar"
        zIndex={1000}
        okButtonProps={{ form: 'form-producto', key: 'submit', htmlType: 'submit' }}
    >
        <ModalInventarios  {...props} />
    </Modal>

}