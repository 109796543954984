import React, { Component } from 'react';
import { Button, Card, Col, Divider, Image, Layout, List, Modal, Popconfirm, Row, Select, Space, Typography, message } from 'antd';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { LinkOutlined } from '@ant-design/icons';

//compoenntes
import User from '../../../../Hooks/Logged';
import { getResponseError, RenderMoney } from '../../../Utils';
import useSearch from '../../../../Hooks/useSearch';
import usePermissions from '../../../../Hooks/usePermissions';
import { ButtonDelete, ButtonEdit } from '../../../Widgets/Buttons';
import FloatingButton from '../../../Widgets/Floating Button/FloatingButton';

const { Content } = Layout;
const { Text, Title } = Typography
const { Option } = Select
const { confirm } = Modal


/**
 *
 *
 * @export
 * @class ProductosVentas
 * @extends {Component}
 * @description Vista donde se listan todos los ProductosVentas
 */
class ProductosVentas extends Component {



    /**
     *Creates an instance of ProductosVentas.
     * @param {*} props
     * @memberof ProductosVentas
     */
    constructor(props) {
        super(props)
        this.state = {
            loading: false,

            detalles: []
        }
    }

    drawerFiltros = React.createRef()

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        if(this.props.orden_compra_id)
            this.getVenta()

    }


    getVenta = () => {
        this.setState({loading: true})
        axios.get('/orden-compra/' + this.props.orden_compra_id)
        .then(({data}) => {
            this.setState({detalles: data.detalles})
            console.log("data.detalles", data.detalles);
        }).catch(error => {
            message.error(getResponseError(error.response, "Error al obtener los detalles"));
        }).finally(() => {
            this.setState({loading: false})
        })
    }

    render() {
        const { detalles, loading } = this.state
        return (
            <>
                <Row gutter={[24, 24]} className='venta-detalles'>
                    <Col xs={24} className="">
                        <List
                            loading={this.state.loading}
                            className="component-list"
                            itemLayout="horizontal"
                            locale={{ emptyText: "Venta sin detalles" }}
                            dataSource={detalles}
                            header={
                                <Row align="middle">
                                    <Col flex="auto">
                                        <Row className="width-100 " align="middle" gutter={[0, 12]}>
                                            <Col span={14} >
                                                <Text strong ellipsis>Información de los producto Solicitados</Text>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            }
                            renderItem={item => (
                                <List.Item className="component-list-item detalle">
                                    <Card className="card-list">
                                        <Row className="width-100" gutter={[8, 8]} align={"middle"}>
                                            <Col span={22}>
                                                <Row gutter={[12, 12]} wrap={false}>
                                                    <Col flex="none">
                                                        <Image
                                                            placeholder
                                                            className='detalle-img'
                                                            {...(item.producto?.imagenes?.length > 0 && {src: axios.defaults.baseURL + "/productos/" + item.producto?._id + "?imagen=" + item.producto.imagenes[0].file + "&Authorization=" + sessionStorage.getItem("token")})}
                                                        />
                                                    </Col>
                                                    <Col flex="auto" className='detalle-info'>
                                                        <Space className='width-100' direction='vertical' size={0}>
                                                            <Text strong className='detalle-name'>{item.modelo ?? "--"} - {item.nombre ?? "--"} </Text>
                                                            <Text className='detalle-data'>{item.producto_codigo} / {item.descripcion}</Text>
                                                            <Text >Cantidad: {item.cantidad} {item.unidad}</Text>
                                                            <Text >Precio Venta: {item.precio_venta} {item.moneda}</Text>
                                                            <Text strong >Subtotal: <RenderMoney monto={item.subtotal} suffix={item.moneda}/></Text>
                                                            
                                                        </Space>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </>
        )
    }
}



export default function (props) {
    const { setShow } = useSearch()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canCreate: ["ventas", "productos-ventas", "create"],
        canEdit: ["ventas", "productos-ventas", "edit"],
        canDelete: ["ventas", "productos-ventas", "delete"],
    })

    const navigate = useNavigate();


    return <ProductosVentas {...props}
        {...permisos}
        setShowSearch={setShow}
        navigate={navigate}
    />
}       