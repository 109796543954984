import { Col, Layout, Row, Typography } from 'antd';
import axios from 'axios';
import ReactECharts from 'echarts-for-react';
import React, { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardSimpleInfo } from './../../Widgets/Cards';

import '../../../Styles/Modules/Admin/CRM.scss';
import PageHeader from '../../Widgets/PageHeader/PageHeader';

const { Content } = Layout;



const labels = {
    0: 'Desistío / Perdido',
    1: 'Registrado',
    2: 'Contactado',
    3: 'Ya ha comprado algo',
    4: 'Pagado'
}


const colorMap = {
    0: "#ff4d4f",
    1: "#ffe608",
    2: "#5465ff",
    3: "#f2802e",
    4: "#00cd98"
}

/**
 *
 *
 * @export
 * @class Usuarios
 * @extends {Component}
 * @description Vista donde se listan todos los usuarios
 */
class Dashboard extends Component {

    back = () => {
        this.props.navigate(-1)
    }


    /**
     *Creates an instance of Usuarios.
     * @param {*} props
     * @memberof Usuarios
     */
    constructor(props) {
        super(props)
        this.state = {
            clientes: 0,
            ventas: 0,
            sin_ventas: 0,
            clientes_ventas: [],
            clientes_estatus: [],
        }
    }

    componentDidMount() {
        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getDashboard()
    }

    /**
     *
     *
     * @memberof Usuarios
     * @method getDashboard
     * @description Obtiene los datos necesarios para la analitica
     */
    getDashboard = () => {
        axios.get('/crm')
            .then(({ data }) => {
                console.log("data", data);
                this.setState({...data})
            })
            .catch(error => {
                console.log(error)
            })
    }

    render() {

        

        const {clientes, sin_ventas, ventas, clientes_ventas, clientes_estatus} = this.state

        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title="CRM"
                />
                <Content className="admin-content  crm content-bg pd-1">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} className="">
                            <Row gutter={[16, 16]}>
                                <Col xs={24} md={8} className="grid">
                                    <CardSimpleInfo
                                        title="Total de Clientes"
                                        info={(clientes).toMoney(true, false)}
                                    />
                                </Col>
                                <Col xs={24} md={8} className="grid">
                                    <CardSimpleInfo
                                        title="Clientes con Compras"
                                        info={(ventas ?? 0).toMoney(true, false)}
                                    />
                                </Col>
                                <Col xs={24} md={8} className="grid">
                                    <CardSimpleInfo
                                        title="Clientes sin Compras"
                                        info={(sin_ventas ?? 0).toMoney(true, false)}
                                    />
                                </Col>
                            </Row>    
                        </Col>
                        <Col xs={24}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} xl={16}>
                                    <CardSimpleInfo
                                        title="Ventas por Clientes"
                                        info={<ReactECharts
                                            option={{
                                                title: {
                                                  text: ''
                                                },
                                                tooltip: {},

                                                xAxis: {
                                                  data: clientes_ventas?.map(v => v.cliente?.nombre_completo ?? v.cliente?.nombre)
                                                },
                                                yAxis: {},
                                                series: [{
                                                    name: 'Ventas',
                                                    type: 'bar',
                                                    data: clientes_ventas?.map(v => v.total)
                                                }]
                                            }}
                                            style={{ height: 400 }}
                                            opts={{ renderer: 'svg' }}
                                        />}
                                    />
                                </Col>
                                <Col xs={24} xl={8}>
                                    <CardSimpleInfo
                                        title="Estatus de Cotizaciones"
                                        info={<ReactECharts
                                            option={{
                                                tooltip: {
                                                    trigger: 'item'
                                                },
                                                series: [
                                                    {
                                                        name: 'Estatus',
                                                        type: 'pie',
                                                        radius: ['40%', '70%'],
                                                        avoidLabelOverlap: false,
                                                        itemStyle: {
                                                            borderRadius: 0,
                                                            borderColor: '#fff',
                                                            borderWidth: 2
                                                        },
                                                        label: {
                                                            show: false,
                                                            position: 'center'
                                                        },
                                                        labelLine: {
                                                            show: false
                                                        },
                                                        data: clientes_estatus?.map(e => ({
                                                            name: labels[e._id], 
                                                            value: e.count,
                                                            itemStyle: { color: colorMap[e._id] } // Color basado en estatus, gris por defecto
                                                        }))
                                                    }
                                                ]
                                            }}
                                            style={{ height: 400 }}
                                            opts={{ renderer: 'svg' }}
                                        />}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Content>

            </>
        )
    }
}

export default props => {

    const navigate = useNavigate()

    return <Dashboard {...props} navigate={navigate}/>
}