import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Menu, Dropdown, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';

// Componentes
import Header from "../Header";
import Footer from "../Footer";

// Estilos
import './categorias.scss';


const { Title, Paragraph } = Typography;

const categorias_temp = require("./categorias.json");

const Categorias = () => {

    let params = useParams()

    // Estado para la categoría seleccionada
    const [selectedCategory, setSelectedCategory] = useState(Object.keys(categorias_temp)[0]); // Primera categoría por defecto
    const [selectedSubCategory, setSelectedSubCategory] = useState(
        categorias_temp[selectedCategory].length > 0 ? categorias_temp[selectedCategory][0].name : ''
    );
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuCatVisible, setCatMenuVisible] = useState(false);

    useEffect(()=>{
        if(params?.categoria){
            setSelectedCategory(params?.categoria)
            setSelectedSubCategory(categorias_temp[params?.categoria].length > 0 ? categorias_temp[params?.categoria][0].name : '')
        }
        window.scrollTo(0, 0);
    },[])


    // Obtener la categoría actual
    let categoria = categorias_temp[selectedCategory];

    // Menú para dispositivos móviles
    const menu = (
        <Menu
            selectedKeys={[selectedSubCategory]}
            onClick={(e) => setSelectedSubCategory(e.key)}
            items={categoria.map(e => ({ key: e.name, label: e.name }))}
        />
    );

    return (
        <div id="productos">
            <Header />
            <main>
                <div className='main-content mt-3'>

                    {/* Botones de categorías en pantallas mayores a 768px */}
                    <div className="categorias-buttons">
                        {Object.keys(categorias_temp).map(cat => (
                            <Button
                                key={cat}
                                onClick={() => {
                                    setSelectedCategory(cat);
                                    setSelectedSubCategory(categorias_temp[cat].length > 0 ? categorias_temp[cat][0].name : '');
                                }}
                                className={selectedCategory === cat ? 'selected' : ''}
                            >
                                {cat.replace(/_/g, " ").toUpperCase()}
                            </Button>
                        ))}
                    </div>

                    <div className="mobile-menu">
                        <Dropdown
                            overlay={<Menu
                                selectedKeys={[selectedCategory]}
                                onClick={(e) => {
                                    setSelectedCategory(e.key)
                                    setSelectedSubCategory(categorias_temp[e.key].length > 0 ? categorias_temp[e.key][0].name : '');
                                    setMenuVisible(false);
                                }}
                                items={Object.keys(categorias_temp).map(e => ({ key: e, label: e.replace(/_/g, " ").toUpperCase() }))}
                            />}
                           
                            trigger={['click']}
                            open={menuCatVisible}
                            onOpenChange={setCatMenuVisible}
                        >
                            <Button icon={<MenuOutlined />} type="primary" block>
                                Seleccionar Categoría
                            </Button>
                        </Dropdown>
                    </div>

                    <Row gutter={[16, 16]} className="responsive-row">
                        {/* Menú lateral - Se oculta en pantallas pequeñas */}
                        <Col xs={0} md={6} lg={6}>
                            <Menu
                                mode="vertical"
                                selectedKeys={[selectedSubCategory]}
                                onClick={(e) => setSelectedSubCategory(e.key)}
                                items={categoria.map(e => ({ key: e.name, label: e.name }))}
                            />
                        </Col>

                        {/* Botón de menú para móviles */}
                        <Col xs={24} md={0} className="mobile-menu">
                            <Dropdown overlay={menu} trigger={['click']} open={menuVisible} onOpenChange={setMenuVisible}>
                                <Button icon={<MenuOutlined />} type="primary" block>
                                    Seleccionar Sub - Categoría
                                </Button>
                            </Dropdown>
                        </Col>

                        {/* Contenido dinámico basado en la subcategoría seleccionada */}
                        <Col xs={24} md={18} lg={18} className='categorias-content'>
                            <Row>
                                {categoria
                                    .filter(sub_categoria => sub_categoria.name === selectedSubCategory)
                                    .map((sub_categoria, index) => (
                                        <Col span={24} key={index} className="category-detail">
                                            <Title level={2} className="category-title">{sub_categoria.name}</Title>

                                            {/* Mostrar elementos si existen */}
                                            {sub_categoria.items && sub_categoria.items.length > 0 && (
                                                <>
                                                    <Title level={4}>Productos:</Title>
                                                    <ul>
                                                        {sub_categoria.items.map((item, idx) => (
                                                            <li key={idx}>{item}</li>
                                                        ))}
                                                    </ul>
                                                </>
                                            )}

                                            {/* Mostrar materiales si existen */}
                                            {sub_categoria.materiales && sub_categoria.materiales.length > 0 && (
                                                <>
                                                    <Title level={4}>Materiales:</Title>
                                                    <Paragraph>{sub_categoria.materiales.join(", ")}</Paragraph>
                                                </>
                                            )}

                                            {/* Mostrar especificaciones si existen */}
                                            {sub_categoria.especificaciones && sub_categoria.especificaciones.length > 0 && (
                                                <>
                                                    <Title level={4}>Especificaciones:</Title>
                                                    <Paragraph>{sub_categoria.especificaciones.join(", ")}</Paragraph>
                                                </>
                                            )}

                                            {/* Mostrar capacidades si existen */}
                                            {sub_categoria.capacidades && sub_categoria.capacidades.length > 0 && (
                                                <>
                                                    <Title level={4}>Capacidades:</Title>
                                                    <Paragraph>{sub_categoria.capacidades.join(", ")}</Paragraph>
                                                </>
                                            )}

                                            {/* Mostrar medidas si existen */}
                                            {sub_categoria.medidas && (
                                                <>
                                                    <Title level={4}>Medidas:</Title>
                                                    <Paragraph>{sub_categoria.medidas}</Paragraph>
                                                </>
                                            )}

                                            {/* Mostrar extremos si existen */}
                                            {sub_categoria.extremos && sub_categoria.extremos.length > 0 && (
                                                <>
                                                    <Title level={4}>Extremos:</Title>
                                                    <Paragraph>{sub_categoria.extremos.join(", ")}</Paragraph>
                                                </>
                                            )}

                                            {/* Mostrar descripción si existe */}
                                            {sub_categoria.descripcion && (
                                                <>
                                                    <Title level={4}>Descripción:</Title>
                                                    <Paragraph>{sub_categoria.descripcion}</Paragraph>
                                                </>
                                            )}

                                            {/* Mostrar aleaciones si existe */}
                                            {sub_categoria.aleacion && (
                                                <>
                                                    <Title level={4}>Aleación:</Title>
                                                    <Paragraph>{sub_categoria.aleacion}</Paragraph>
                                                </>
                                            )}

                                            {/* Mostrar espesores si existe */}
                                            {sub_categoria.espesores && (
                                                <>
                                                    <Title level={4}>Espesores:</Title>
                                                    <Paragraph>{sub_categoria.espesores}</Paragraph>
                                                </>
                                            )}
                                        </Col>
                                    ))}
                            </Row>
                        </Col>
                    </Row>
                </div>
            </main>
            <Footer />
        </div>
    );
};

export default Categorias;
