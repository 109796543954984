import React, { Component } from 'react';
import {
    Row, Col, Typography,
    Layout, Space, message, List, Card, Dropdown, Tag, Menu
} from 'antd'
import { useNavigate } from "react-router-dom";
import axios, { } from 'axios';

//Componentes
import Sort from "../../Widgets/Sort"
import User from '../../../Hooks/Logged'
import useSearch from '../../../Hooks/useSearch';
import usePermissions from '../../../Hooks/usePermissions';
import { getResponseError, RenderMoney } from '../../Utils';
import PageHeader from '../../Widgets/PageHeader/PageHeader';
import FloatingButton from '../../Widgets/Floating Button/FloatingButton'
import { ButtonDelete, ButtonEdit, ButtonView } from '../../Widgets/Buttons';


import dayjs from 'dayjs';

const { Content } = Layout;
const { Text } = Typography


/**
 *
 *
 * @export
 * @class Clientes
 * @extends {Component}
 * @description Vista donde se listan todos los Clientes
 */
class VentasList extends Component {



    /**
     *Creates an instance of Clientes.
     * @param {*} props
     * @memberof Clientes
     */
    constructor(props) {
        super(props)
        //let { page, limit, filters, search, sort } = this.props.params
        this.state = {
            loading: false,

            ventas: {
                data: [],
                page: 1,
                limit: 10,
                filters: [],
                total: 0,
                sort: {},
            },
            vendedor_id: undefined,
            modalVenta: false,
            search: this.props.search,
        }
    }

    drawerFiltros = React.createRef()

    componentDidMount() {
        this.props.setShowSearch(true)

        axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('token')
        this.getOrdenes();
        // this.getAsesores()

    }



    /**
    * @memberOf Clientes
    * @method getOrdenes
    * @description Obtiene ls lista de Clientes
    */
    getOrdenes = ({

        page = this.state.ventas.page,
        limit = this.state.ventas.limit,
        search = this.state.ventas.search,
        sort = this.state.ventas.sort,

    } = this.state.ventas) => {
        const params = { page, limit }
        if (sort && Object.values(sort).length > 0) params.sort = JSON.stringify(sort)
        if (search) params.search = search

        this.setState({ ventas: { ...this.state.ventas, page, limit, search, sort }, loading: true })
        axios.get('/ordenes-compras', {
            params
        })
            .then(({ data }) => {

                console.log("data ventas", data)
                this.setState({
                    ventas: {
                        ...this.state.ventas,
                        ...data,
                        search
                    }
                })

            })
            .catch(error => {
                //console.log(error)
                message.error('Error al obtener la Información')
            })
            .finally(() => this.setState({ loading: false, clearCheckbox: false }))
    }


    renderEstatus = (status) => {

        switch (status) {
            case 1:
                return <Tag color="#FFE608">Pendiente</Tag>
            case 2:
                return <Tag color="#5465FF">Completo</Tag>
            case 3:
                return <Tag color="#F2802E">Cancelado</Tag>
            default:
                return <Tag color="#FFE608">Pendiente</Tag>
        }
    }

    updateEstatus = (orden_id, status) => {
        axios.put('/orden-compra', {
            orden_compra_id: orden_id,
            status
        }).then(success => {
            message.success("Estatus de venta actualizada")
            this.getOrdenes()
        }).catch(error => {
            message.error("Error al actualizar el estatus")
        })
    }

    /**
     *
     *
     * @param {*} key
     * @memberof VentasList
    */
    setSort = (key) => {
        let value;
        switch (this.state.ventas.sort[key]) {
            case 1:
                value = -1
                break;
            case -1:
                value = undefined
                break;
            default:
                value = 1
                break;
        }
        this.getOrdenes({
            sort: {
                ...this.state.ventas.sort,
                [key]: value
            }
        })
    }

    render() {
        const { ventas, loading } = this.state
        return (
            <>
                <PageHeader
                    className="admin-page-header"
                    title={<>
                        Ordenes Compras
                    </>}
                />
                <Content
                    className="admin-content content-bg ventas pd-1"
                    id="ventas-layout"
                >
                    <Row gutter={[24, 24]}>
                        <Col xs={24} className="">
                            <List
                                loading={this.state.loading}
                                className="component-list"
                                itemLayout="horizontal"
                                locale={{ emptyText: "Sin registros" }}
                                dataSource={ventas.data}
                                pagination={{
                                    current: ventas.page,
                                    pageSize: ventas.limit,
                                    total: ventas.total,
                                    pageSizeOptions: [10, 20, 50, 100, 500, 1000],
                                    position: 'bottom',
                                    className: "flex-left ml-1",
                                    onChange: (page, limit) => this.getOrdenes({ page, limit })
                                }}
                                header={
                                    <Row align="middle">

                                        <Col flex="auto">
                                            <Row className="width-100 " align="middle" gutter={[0, 12]}>
                                                <Col xs={3} className="center" >                                                    
                                                    <Text ellipsis strong>Folio</Text>
                                                </Col>
                                                <Col xs={3} className="center" >
                                                    <Text ellipsis strong>Orden ID</Text>
                                                </Col>
                                                <Col xs={3} className="center" style={{ color: "gray" }} >
                                                    <Text ellipsis strong>Fecha</Text>
                                                </Col>
                                                <Col xs={3} className="center" >
                                                    <Text ellipsis strong>Productos</Text>
                                                </Col>
                                                <Col xs={5} className="center" >
                                                    <Text ellipsis strong>Contacto</Text>
                                                </Col>
                                                <Col xs={3} className="center" >
                                                    <Text ellipsis strong>Estatus</Text>
                                                </Col>
                                                <Col xs={3} className="center" >
                                                    <Text ellipsis strong>Monto</Text>
                                                </Col>
                                                <Col xs={3} className="center" >
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                }

                                renderItem={item => (
                                    <List.Item className="component-list-item">
                                        <Card className="card-list">
                                            <Row align="middle">
                                                <Col flex="auto">
                                                    <Row className="width-100 " align="middle" gutter={[0, 12]}>
                                                        <Col xs={3} className="center" >
                                                            <Text ellipsis strong>{item.folio}</Text>
                                                        </Col>
                                                        <Col xs={3} className="center" >
                                                            <Text ellipsis >{item.order_id}</Text>
                                                        </Col>
                                                        <Col xs={3} className="center" >
                                                            <Text ellipsis >{dayjs(item.fecha).format('DD/MM/YYYY')}</Text>
                                                        </Col>
                                                        <Col xs={3} className="center" >
                                                            <Text ellipsis >Productos ({item.detalles?.length})</Text>
                                                        </Col>
                                                        <Col xs={5} className="center" >
                                                            <Text ellipsis >{item?.contacto?.nombre}</Text>
                                                        </Col>
                                                        <Col xs={3} className="center" >
                                                            <Dropdown
                                                                overlay={<Menu>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 1)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`ventas-circle small circle-1`} />
                                                                            <Text>Pendiente</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 2)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`ventas-circle small circle-2`} />
                                                                            <Text>Completo</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                    <Menu.Item onClick={() => this.updateEstatus(item._id, 3)}>
                                                                        <Space direction="horizontal" size={8}>
                                                                            <div className={`ventas-circle small circle-3`} />
                                                                            <Text>Cancelado</Text>
                                                                        </Space>
                                                                    </Menu.Item>
                                                                </Menu>}
                                                                className="ventas-dropdown"
                                                                trigger={['click']}
                                                                disabled={!this.props.canEdit}
                                                            >
                                                                {this.renderEstatus(item.status)}
                                                            </Dropdown>
                                                        </Col>
                                                        <Col xs={3} className="center" >
                                                            <RenderMoney monto={item.total} suffix={item.moneda}/>
                                                        </Col>
                                                        <Col xs={1} className="center" >
                                                            <Space wrap>
                                                                <ButtonView
                                                                    onClick={()=>this.props.navigate(`/admin/ordenes-compras/${item._id}`)}
                                                                />
                                                            </Space>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </Row>
                </Content>
                {/* </Spin> */}

                {this.props.canCreate && <FloatingButton title="New Client" disabled={!this.props.canCreate} onClick={() => this.setState({ modalVenta: true, venta_id: null })} />}
            </>
        )
    }
}



export default (props) => {
    const { setShow } = useSearch()

    const user = React.useContext(User)
    const permisos = usePermissions(user?.rol_id?.permisos, {
        canCreate: ["ventas", "create"],
        canEdit: ["ventas", "edit"],
        canDelete: ["ventas", "delete"]
    })

    const navigate = useNavigate();


    return <VentasList {...props}
        {...permisos}
        setShowSearch={setShow}
        navigate={navigate}
    />
}