import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Typography, Image,  } from 'antd';
import { GiTargetArrows } from "react-icons/gi";
import { FaFlagCheckered } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";
import axios from 'axios';


const { Title, Text  } = Typography;

const Catalogos = () => {

	const [catalagos, setCatalogos] = useState([])

	useEffect(()=>{
		getCatalagos()
	},[])

	//obtiene el listado de catalogos
	const getCatalagos = async () => {
		try{

			let { data } = await axios.get('/public/catalogos')
			console.log(data)
			setCatalogos(data.data)

		}catch(error){

		}
	}

	return (
		<Row className='nosotros mt-3 mb-3' gutter={[16, 16]}>
			<Col span={24}>
				{ catalagos.length > 0 ? <Title className='subtitle text-center mb-3' style={{
					textTransform: "uppercase",
					color: "#EE492A",
					fontSize: "16px",
					fontWeight: "400",
					fontFamily: "Ubuntu",
					letterSpacing: "1px"}}>NUESTROS CATALOGOS</Title> : null }
			</Col>
			{catalagos.map(e => (
				<Col xs={24} sm={12} md={6}>
					<a href={axios.defaults.baseURL + "/public/catalogo/" + e?._id + "?archivo=true"} target='_blank'>
						<div className='flex-column'>
							<Image src='./pdf.png' width={200} preview={false}/>
							<Text className='mt-1'>{e.nombre}</Text>
						</div>
					</a>
				</Col>
			))}
			
		</Row>
	)
}

export default Catalogos