import React, { useState, useEffect } from 'react';
import { Col, Image, Row, Select, Space, Typography, message } from 'antd'
import axios from "axios"

const { Option } = Select 
const { Text } = Typography

/**
 * @const SelectProducto
 * @description Select para los productos registrados en el sistema
 */
const SelectProducto = (props) => {

    const { 
        value, 
        onChange, 
        placeholder = "Seleccione el producto", 
        onSelect = () => {}, 
        disabled = false, 
        className = "", 
        params = {}, 
        bordered, 
        allowClear = true, 
        onClear = () => {},
        mode = null,
        labelInValue
    } = props

    const [ productos, setProductos ] = useState({
        data: [],
        page: 1,
        limit: 10,
        total: 0,
        search: null,
    })


    /**
     * @const getProductos
     * @description Obitiene los usaurios
     */
    const getProductos = ({
        page, 
        limit, 
        search, 
    } = productos) => {

        axios.get('/productos', {
            params: {
                page,
                limit,
                search,
                ...params,
            },
            headers: { Authorization: sessionStorage.getItem('token') }
        }).then(({ data }) => {
            setProductos(data)
        }).catch(error => {
            console.log("ERR, error",error)
            message.error(error?.response?.data?.message ?? 'Error getting products')
        })
    }

    //componentDidMount
    useEffect(() => {
        getProductos()
    }, [])

   /* useEffect(() => {
        if (value)
            onChange(value?.value ?? value)
    }, [value])*/

    return (
        <Select
            bordered={bordered}
            className={className}
            disabled={disabled}
            placeholder={placeholder}
            allowClear={allowClear}
            showSearch
            filterOption={false}
            labelInValue
            mode={mode}
            onSearch={(search)=> getProductos({search})}
            onSelect={(area)=> {
                if(mode === null && !labelInValue){
                    onChange(area.value)
                    onSelect(area.value)
                }
                if(mode === null && labelInValue){
                    onChange(area)
                    onSelect(area)
                }
            }}
            onChange={(values)=>{
                if(mode === "multiple" && !labelInValue){
                    let arr = values.map(e => e.value)
                    onChange(arr)
                    onSelect(arr)
                }

                if(mode === "multiple" && labelInValue){
                    onChange(values)
                    onSelect(values)
                }
            }}
            value={value}
            onClear={()=>{
                onClear()
                onChange(undefined)
                onSelect(undefined)
            }}
            optionLabelProp='label'
        >
            {
                productos?.data?.map(({ _id, nombre, modelo, marca, inventario_id, imagenes}) => <Option label={`${nombre} | ${modelo} | ${marca}`} value={_id} key={_id}>
                    <Row gutter={[12, 12]} wrap={false}>
                        <Col flex="none">
                            <Image
                                placeholder
                                width={60}
                                height={60}
                                style={{objectFit: 'cover'}}
                                {...(imagenes?.length > 0 && {src: axios.defaults.baseURL + "/productos/" + _id + "?imagen=" + imagenes[0].file + "&Authorization=" + sessionStorage.getItem("token")})}
                            />
                        </Col>
                        <Col flex="auto">
                            <Space className='width-100' direction='vertical' size={0}>
                                <Text strong style={{fontSize: 14}}>{nombre ?? "--"}/{modelo ?? "--"}</Text>
                                <Text style={{fontSize: 12}}>{marca}</Text>
                                {inventario_id && <Text style={{fontSize: 12}}>{inventario_id?.existencias} Disponibles</Text>}
                            </Space>
                        </Col>
                    </Row>
                </Option>)
            }
        </Select>
    );
}



export default SelectProducto;